<!--
 * =====================================================================
 * 작 업 명  : 로그인 처리
 * 파 일 명  : Login.vue
 * 변경이력
 * 2022-02-14 / 정태화 / 최초작성
 * 2023-02-10 / 이성준
 * =====================================================================
 -->
<template>
    <div></div>
</template>
<script>
import {$session} from "dot-framework";

export default {
    // 최초 호출
    mounted() {
        this.callback();
    },
    methods: {
        callback() {
            const siteNo = process.env.VUE_APP_SITE_NO;
            const compNo = $session.getCompNo() || this.$store.getters.COMP_NO || 'C220121001';
            const url = `${process.env.VUE_APP_SSO_DOMAIN}/login?cb=${window.location.protocol}//${window.location.host}/login-callback`;
            const param = "&s=" + siteNo + "&c=" + compNo;

            location.href = url + param;
        }
    },
}
</script>
<style>
</style>
